import React from 'react'

import { Container } from 'react-bootstrap'
import NavbarSite from './Commonds/NavbarSite'
import HomeCarousel from './Commonds/HomeCarousel'
import HomeCardProducto from './Commonds/HomeCardProducto'
import HomeBanner from './Commonds/HomeBanner'
import HomeProductosMit from './Commonds/HomeProductosMit'
import HomeFormulario from './Commonds/HomeFormulario'

const Home = () => {
  return (
    <Container fluid className='p-0 mb-0' >
        <NavbarSite />
        <HomeCarousel />
        <article className="container text-center">
          <div className="row homeCardProducto__container">
            <div className="col-6 col-md-3">
              <HomeCardProducto imgLink={"/productos-kive/"} imgUrl={'./images/productos/productos_thumbs--cintas--kive.jpg'} imgAlt={"Cintas Kive"}/>
            </div>
            <div className="col-6 col-md-3">
              <HomeCardProducto imgLink={"/productos-rami-cintas/"} imgUrl={'./images/productos/productos_thumbs--cintas--rami.jpg'} imgAlt={"Cintas Rami"}/>
            </div>
            <div className="col-6 col-md-3">
              <HomeCardProducto imgLink={"/productos-rami-hilos"} imgUrl={'./images/productos/productos_thumbs--hilos--rami.jpg'} imgAlt={"Hilos Rami"}/>
            </div>
            <div className="col-6 col-md-3">
              <HomeCardProducto imgLink={"/productos-kive-racionadores/"} imgUrl={'./images/productos/productos_thumbs--racionadores.jpg'} imgAlt={"Kive Racionadores"}/>
            </div>
          </div>
        </article>
        <HomeBanner/>
        <HomeProductosMit/>
        <HomeFormulario />
    </Container>
  )
}

export default Home