import { Container } from 'react-bootstrap'

import NavbarPages from '../NavbarPages'
import HeaderPages from '../HeaderPages'
import KiveUso from '../../../Resourses/products/kive-uso-general.svg'

const imgHeader = "./images/productos/header-productos-kive-papel.jpg";
const imgThumbIzq = "./images/productos/productos_thumbs--cuadro-kive-cinta-adhesiva-doble-faz-esfumada.jpg";
const altText = "CINTA ADHESIVA DOBLE FAZ ESPUMADA";

const tipoColor = 'productos__detail--tipoAzul'
const detailLine1 = "CINTA";
const detailLine2 = "ADHESIVA";
const detailLine3 = "DOBLE FAZ ESPUMADA";

const imgTable ="./images/productos/kive/cuadro-kive-cinta-adhesiva-doble-faz-esfumada.jpg";


const textoDetail = 'Cinta de espuma de 1 mm. de espesor adhesivada en ambas caras, resistente para interiores o exteriores. Ideal para montajes livianos y cartelería. Se adhiere a superficies lisas y rugosas, se  caracteriza por unir en forma rápida y fácil.'

const imgThumb1 = './images/productos/kive-cinta-adhesiva-doble-faz-espumada/kive-cinta-adhesiva-doble-faz-espumada-1.jpg';
const imgThumb1Alt = 'CINTA ADHESIVA DOBLE FAZ ESPUMADA A granel';

const imgThumb2 = './images/productos/kive-cinta-adhesiva-doble-faz-espumada/kive-cinta-adhesiva-doble-faz-espumada-2.jpg';
const imgThumb2Alt = 'CINTA ADHESIVA DOBLE FAZ ESPUMADA 18mm';

const imgThumb3 = './images/productos/kive-cinta-adhesiva-doble-faz-espumada/kive-cinta-adhesiva-doble-faz-espumada-3.jpg';
const imgThumb3Alt = 'CINTA ADHESIVA DOBLE FAZ ESPUMADA 24mm';

const imgThumb4 = './images/productos/kive-cinta-adhesiva-doble-faz-espumada/kive-cinta-adhesiva-doble-faz-espumada-4.jpg';
const imgThumb4Alt = 'CINTA ADHESIVA DOBLE FAZ ESPUMADA 12mm';

const imgThumb5 = './images/productos/kive-cinta-adhesiva-doble-faz-espumada/kive-cinta-adhesiva-doble-faz-espumada-5.jpg';
const imgThumb5Alt = 'CINTA ADHESIVA DOBLE FAZ ESPUMADA 18mm';

const imgThumb6 = './images/productos/kive-cinta-adhesiva-doble-faz-espumada/kive-cinta-adhesiva-doble-faz-espumada-6.jpg';
const imgThumb6Alt = 'CINTA ADHESIVA DOBLE FAZ ESPUMADA 24mm';


const CintaAdhesivaDobleFazEspumada = () => {
  return (
    <Container className='mb-0'>
        <NavbarPages className="p-0" />
        <div className='row'>
          <div className='col-12 mb-4'>
            <HeaderPages img={imgHeader} alt={"Productos Kive Cintas"} />
          </div>
          <div className='col-12 col-sm-6 col-md-5 productos__imgContainer'>
            <img 
              src={imgThumbIzq} 
              alt={altText} 
              className='d-block w-100 productos__imgContainer--img' 
              loading='lazy'/>
          </div>
          <div className='col-12 col-sm-12 col-md-7 productos__vert--int mb-4 mt-1'>
            <div className='row productos__detail'>
              <div className='col-12'>
                <p className={`productos__detail--tipo ${tipoColor} pb-0 mb-0`}>{detailLine1}
                 <span className={`productos__detail--caracteristica ${tipoColor}`}> {detailLine2}</span>
                </p>
                <p className={`productos__detail--caracteristica ${tipoColor}`}>{detailLine3}</p>
                <img src={KiveUso} className='d-block productos__detail--svg' alt='' loading="lazy" />
              </div>
              <div className='col-12 productos__detail'>
                <img  src={imgTable} 
                      className='d-block w-100' 
                      alt={altText}
                      loading="lazy" />
              </div>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-12 productos__vert mb-4 mt-1'>
            <p className='site_text productos__text--int mb-0'> 
              {textoDetail}
            </p>
          </div>
        </div>
        <div className='row'>
        
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb2} className='d-block w-100 p-0 m-0 ' alt={ imgThumb2Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb3} className='d-block w-100 p-0 m-0 ' alt={ imgThumb3Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb1} className='d-block w-100 p-0 m-0 ' alt={ imgThumb1Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb4} className='d-block w-100 p-0 m-0 ' alt={ imgThumb4Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb5} className='d-block w-100 p-0 m-0 ' alt={ imgThumb5Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb6} className='d-block w-100 p-0 m-0 ' alt={ imgThumb6Alt} loading="lazy" />
            </div>
        </div>       
      </Container>
    
  )
}

export default CintaAdhesivaDobleFazEspumada