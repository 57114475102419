import { Container } from 'react-bootstrap'

import NavbarPages from '../NavbarPages'
import HeaderPages from '../HeaderPages'
import KiveUso from '../../../Resourses/products/kive-uso-racionadores-escritorio.svg'

const imgHeader = "./images/productos/header-productos-kive-racionador-escritorio.jpg";
const imgThumbIzq = "./images/productos/productos_thumbs--racionadores-escritorio-kive.jpg";
const altText = "CINTA DE EMPAQUE";

const tipoColor = 'productos__detail--tipoEmpacadoresEscritorio'

const detailLine1 = "RACIONADORES";
// const detailLine2 = "ADHESIVA";
const detailLine3 = "DE ESCRITORIO";

const imgTable ="./images/productos/kive/cuadro-kive-racionador-de-escritorio.jpg";

const textoDetail1 = 'Ideal para oficinas, comercios y/o espacios de trabajo. A mayor peso del racionador, mejor será su apoyo sobre el área de trabajo, evitando el movimiento cuando se usa. Tienen cuchillas metálicas de excelente corte.'
const textoDetail2 = 'Diseños modernos y elegantes.'
const textoDetail3 = 'Disponibles para cintas con bujes chicos ( 1”) y grandes ( 3”) , admiten cintas hasta 24 mm. de ancho y hasta 60 metros de largo.'

const imgThumb1 = './images/productos/kive-racionadores-escritorio/kive-racionadores-escritorio-1.jpg';
const imgThumb1Alt = 'Cintas de seguridad.';

const imgThumb2 = './images/productos/kive-racionadores-escritorio/kive-racionadores-escritorio-2.jpg';
const imgThumb2Alt = 'intas de seguridad.';

const imgThumb3 = './images/productos/kive-racionadores-escritorio/kive-racionadores-escritorio-3.jpg';
const imgThumb3Alt = 'intas de seguridad.';

const imgThumb4 = './images/productos/kive-racionadores-escritorio/kive-racionadores-escritorio-4.jpg';
const imgThumb4Alt = 'Cintas de seguridad blanca.';

const imgThumb5 = './images/productos/kive-racionadores-escritorio/kive-racionadores-escritorio-7.jpg';
const imgThumb5Alt = 'Cintas de seguridad amarilla.';

const imgThumb6 = './images/productos/kive-racionadores-escritorio/kive-racionadores-escritorio-6.jpg';
const imgThumb6Alt = 'Cintas de seguridad amarilla.';


const imgThumb7 = './images/productos/kive-racionadores-escritorio/kive-racionadores-escritorio-5.jpg';
const imgThumb7Alt = 'Cintas de seguridad blanca.';

const imgThumb8 = './images/productos/kive-racionadores-escritorio/kive-racionadores-escritorio-8.jpg';
const imgThumb8Alt = 'Cintas de seguridad amarilla.';

const imgThumb9 = './images/productos/kive-racionadores-escritorio/kive-racionadores-escritorio-9.jpg';
const imgThumb9Alt = 'Cintas de seguridad amarilla.';

const RacionadoresEscritorioKive = () => {
  return (
    <Container className='mb-0'>
        <NavbarPages className="p-0" />
        <div className='row'>
          <div className='col-12 mb-4'>
            <HeaderPages img={imgHeader} alt={"Productos Kive Cintas"} />
          </div>
          <div className='col-12 col-sm-6 col-md-5 productos__imgContainer'>
            <img 
              src={imgThumbIzq} 
              alt={altText} 
              className='d-block w-100 productos__imgContainer--img' 
              loading='lazy'/>
          </div>
          <div className='col-12 col-sm-12 col-md-7 productos__vert--int mb-4 mt-1'>
            <div className='row productos__detail'>
              <div className='col-12'>
                <p className={`productos__detail--tipo ${tipoColor}`}>{detailLine1}
                 {/* <span className={`productos__detail--caracteristica ${tipoColor}`}> {detailLine2}</span> */}
                </p>
                <p className={`productos__detail--caracteristica ${tipoColor}`}>{detailLine3}</p>
                <img src={KiveUso} className='d-block productos__detail--svg' alt='' loading="lazy" />
              </div>
              <div className='col-12 productos__detail'>
                <img  src={imgTable} 
                      className='d-block w-100' 
                      alt={altText}
                      loading="lazy" />
              </div>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-12 productos__vert mb-4 mt-1'>
            <p className='site_text productos__text--int mb-0'> 
              {textoDetail1}<br /><br />
              {textoDetail2}<br /><br />
              {textoDetail3}<br />
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb1} className='d-block w-100 p-0 m-0 ' alt={ imgThumb1Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb2} className='d-block w-100 p-0 m-0 ' alt={ imgThumb2Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb3} className='d-block w-100 p-0 m-0 ' alt={ imgThumb3Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb4} className='d-block w-100 p-0 m-0 ' alt={ imgThumb4Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb5} className='d-block w-100 p-0 m-0 ' alt={ imgThumb5Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb6} className='d-block w-100 p-0 m-0 ' alt={ imgThumb6Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb7} className='d-block w-100 p-0 m-0 ' alt={ imgThumb7Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb8} className='d-block w-100 p-0 m-0 ' alt={ imgThumb8Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb9} className='d-block w-100 p-0 m-0 ' alt={ imgThumb9Alt} loading="lazy" />
            </div>
        </div>          
      </Container>
    
  )
}

export default RacionadoresEscritorioKive