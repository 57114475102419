import React from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'

import Rami from '../../Resourses/logo-rami.svg'
import RamiIcon from '../../Resourses/logo-rami-color.svg'

import { Link } from 'react-router-dom'

const NavbarPages = () => {
  return (  
          <Navbar  expand="lg pt-0">
            {/* <Navbar bg="light" expand="lg"> */}
              <Container fluid className='p-0'>
                
                <Navbar.Brand href="#" >
                  <Link to={'/'} style={{ cursor: 'pointer'}}>
                    <img src={Rami} alt="Rami Cintas + Hilos" className='navbarPages__logo' />
                  </Link>
                </Navbar.Brand>
                
                <Navbar.Toggle aria-controls="navbarScroll" />
                
                <Navbar.Collapse id="navbarScroll">
                  <Nav

                    navbarScroll
                    className="me-auto my-2 my-lg-0 navbarlinksPagesContainer"
                  >
                    <div className='navbarScroll--pages navbarlinksPages'>
                      <Link to={'/quienes-somos/'} className='link__reset navbarlinks__btn  navbarlinks__btn--pages'> Quienes Somos </Link>
                      <Link to={'/productos-kive/'} className='link__reset navbarlinks__btn  navbarlinks__btn--pages'> KIVE </Link>
                      <Link to={'/productos-rami/'} className='link__reset navbarlinks__btn  navbarlinks__btn--pages'> RAMI </Link>
                      <Link to={'/contacto/'} className='link__reset navbarlinks__btn  navbarlinks__btn--pages'> CONTACTO </Link>
                    </div>
                    <hr className='item__mobile'/>
                    <div className='row navbarlinks__list item__mobile'>
                      <Nav.Link href="#link" className="item__mobile">Facebook</Nav.Link>
                      <Nav.Link href="#link" className="item__mobile">Instagram</Nav.Link>
                      <Nav.Link href="#link" className="item__mobile">Linkedin</Nav.Link>
                      <Nav.Link href="#link" className="item__mobile">WhastApp</Nav.Link>
                      <Nav.Link href="#link" className="item__mobile">Email</Nav.Link>
                      <Nav.Link href="#link" className="item__mobile" disabled>News</Nav.Link>
                    </div>
                  </Nav>
                  <Navbar.Brand href="#" className='p-0 m-0'>
                    <Link to={'/'} style={{ cursor: 'pointer'}}>
                      <img src={RamiIcon} alt="Rami Cintas + Hilos" className='navbarPages__logo--rami' />
                    </Link>
                  </Navbar.Brand>
                </Navbar.Collapse>

              </Container>
            </Navbar>
        
      )
    }
    
  export default NavbarPages
