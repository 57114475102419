import { Container } from 'react-bootstrap'

import NavbarPages from '../NavbarPages'
import HeaderPages from '../HeaderPages'
import KiveUso from '../../../Resourses/products/kive-uso-general.svg'

const imgHeader = "./images/productos/header-productos-kive-papel.jpg";
const imgThumbIzq = "./images/productos/productos_thumbs--cinta-papel-kive.jpg";
const altText = "CINTA ADHESIVA DOBLE FAZ ESPUMADA";

const tipoColor = 'productos__detail--tipoAzul'
const detailLine1 = "CINTAS";
const detailLine2 = "DE PAPEL";
// const detailLine3 = "DOBLE FAZ ESPUMADA";

const imgTable ="./images/productos/kive/cuadro-kive-cinta-papel.jpg";


// const textoDetail = 'Cinta de enmascarar de uso general desarrolladas para el uso diario, siendo ideales para trabajos escolares , empalmes, rotular, decoración, etc..'
const textoDetail = 'Cintas de enmascarar de uso general desarrolladas para el uso diario, siendo ideales para trabajos escolares, empalmes, rotular, decoración, etc..'



const imgThumb1 = './images/productos/kive/kive-cinta-de-papel-1.jpg';
const imgThumb1Alt = 'Cinta de papel 18mm';

const imgThumb2 = './images/productos/kive/kive-cinta-de-papel-2.jpg';
const imgThumb2Alt = 'CINTA de papel 24mm';

const imgThumb3 = './images/productos/kive/kive-cinta-de-papel-3.jpg';
const imgThumb3Alt = 'CINTA de papel a granel.';



const CintaPapelKive = () => {
  return (
    <Container className='mb-0'>
        <NavbarPages className="p-0" />
        <div className='row'>
          <div className='col-12 mb-4'>
            <HeaderPages img={imgHeader} alt={"Productos Kive Cintas"} />
          </div>
          <div className='col-12 col-sm-6 col-md-5 productos__imgContainer'>
            <img 
              src={imgThumbIzq} 
              alt={altText} 
              className='d-block w-100 productos__imgContainer--img' 
              loading='lazy'/>
          </div>
          <div className='col-12 col-sm-12 col-md-7 productos__vert--int mb-4 mt-1'>
            <div className='row productos__detail'>
              <div className='col-12'>
                <p className={`productos__detail--tipo ${tipoColor}`}>{detailLine1}
                 {/* <span className={`productos__detail--caracteristica ${tipoColor}`}> {detailLine2}</span> */}
                </p>
                <p className={`productos__detail--caracteristica ${tipoColor}`}>{detailLine2}</p>
                <img src={KiveUso} className='d-block productos__detail--svg' alt='' loading="lazy" />
              </div>
              <div className='col-12 productos__detail'>
                <img  src={imgTable} 
                      className='d-block w-100' 
                      alt={altText}
                      loading="lazy" />
              </div>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-12 productos__vert mb-4 mt-1'>
            <p className='site_text productos__text--int mb-0'> 
              {textoDetail}
            </p>
          </div>
        </div>
        <div className='row'>
        
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb1} className='d-block w-100 p-0 m-0 ' alt={ imgThumb1Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb2} className='d-block w-100 p-0 m-0 ' alt={ imgThumb2Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb3} className='d-block w-100 p-0 m-0 ' alt={ imgThumb3Alt} loading="lazy" />
            </div>
        </div>       
      </Container>
    
  )
}

export default CintaPapelKive