import React from 'react'
import { Link } from 'react-router-dom'

const HomeCardProducto = ( { imgLink, imgUrl, imgAlt } ) => {
  return (
    <>
      <Link to={imgLink}>
        <img src={imgUrl} alt={imgAlt} className='d-block w-100 productos__imgContainer--img' loading='lazy'/>
      </Link>
    </>
  )
}

export default HomeCardProducto