import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
const HomeProductosMit = () => {
  return (
    <Container className="container text-center homeProductosMit__container">
        <div className='homeProductosMit__title'>Somos distribuidores de productos</div>
        <div className='row justify-content-center homeProductosMit__banners'>
          
          <div className='col-6 col-sm-6 col-md-3 col-lg-2  homeProductosMit__banner'> 
            <Link to={"productos-mit"}><img src="./images/productos/mit/mit-marca.jpg" alt="Mit Marca"  className='d-block w-100 homeProductosMit__image homeCardProducto--img'/></Link>
          </div>
          
          <div className='col-6 col-sm-6 col-md-3 col-lg-2  homeProductosMit__banner'> 
          <Link to={"productos-mit"}><img src="./images/productos/mit/mit-abrochadora.jpg" alt="Mit Abrochadoras" className='d-block w-100 homeProductosMit__image homeCardProducto--img'/></Link>
          </div>
          
          <div className='col-6 col-sm-6 col-md-3 col-lg-2  homeProductosMit__banner'> 
          <Link to={"productos-norte"}><img src="./images/productos/mit/mit-norte.jpg" alt="Mit Norte"               className='d-block w-100 homeProductosMit__image homeCardProducto--img'/></Link>
          </div>
          
          <div className='col-6 col-sm-6 col-md-3 col-lg-2  homeProductosMit__banner'> 
          <Link to={"productos-norte"}> <img src="./images/productos/mit/mit-cuadernos.jpg" alt="Norte cuadernos"     className='d-block w-100 homeProductosMit__image homeCardProducto--img'/></Link>
          </div>
        
        </div>
    </Container>
  )
}

export default HomeProductosMit