import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, HashRouter } from 'react-router-dom';

// import NavbarSite from './Componente/Commonds/NavbarSite';
import Home from './Componente/Home';
import Footer from './Componente/Footer';
import QuienesSomos from './Componente/QuienesSomos';
import Kive from './Componente/Kive';
import Rami from './Componente/Rami.jsx';
import Contacto from './Componente/Contacto';

import CintaAdhesivaTransparente from './Componente/Commonds/Products/cinta-adhesiva-transparente';
import CintaAdhesivaInvisible from './Componente/Commonds/Products/cinta-adhesiva-invisible.jsx';
import CintaAdhesivaDobleFazEspumada from './Componente/Commonds/Products/cinta-adhesiva-doble-faz-espumada.jsx';
import CintaPapelKive from './Componente/Commonds/Products/cinta-de-papel-kive.jsx'
import CintaAdhesivaDobleFaz from './Componente/Commonds/Products/cinta-adhesiva-doble-faz-kive.jsx';
import CintaEmpaqueKive from './Componente/Commonds/Products/cinta-empaque-kive:.jsx';
import CintaDuctTape from './Componente/Commonds/Products/cinta-duct-tape-kive.jsx';
import CintaEmpaqueRami from './Componente/Commonds/Products/cinta-empaque-rami.jsx';
import CintaPapelRami from './Componente/Commonds/Products/cinta-papel-rami.jsx';
import CintaSeguridadRami from './Componente/Commonds/Products/cinta-seguridad-rami';
import HilosPlasticosRami from './Componente/Commonds/Products/hilos-plastico-rami.jsx';
import HilosSisalRami from './Componente/Commonds/Products/hilos-sisal-rami.jsx';
import HilosAlgodonCrudoRami from './Componente/Commonds/Products/hilos-algodon-crudo-rami.jsx';
import HilosAlgodonPulidoRami from './Componente/Commonds/Products/hilos-algodon-pulido-rami.jsx';
import RamiHilos from './Componente/RamiHilos.jsx';
import RamiCintas from './Componente/RamiCintas.jsx';
import KiveRacionadores from './Componente/KiveRacionadores.jsx';
import RacionadoresEmpaqueKive from './Componente/Commonds/Products/racionadores-empaque-kive.jsx';
import RacionadoresEscritorioKive from './Componente/Commonds/Products/racionadores-escritorio-kive.jsx';
import ProductosMit from './Componente/Commonds/Products/productos-mit';
import ProductosNorte from './Componente/Commonds/Products/productos-norte.jsx';

function App() {
  return (
    <div className="conatiner-fluid p-0">
      <HashRouter>
          <Routes>
            <Route exact path='/' element={<Home/>} />
            <Route exact path='/quienes-somos/' element={ <QuienesSomos/> } />
            <Route exact path='/productos-kive/' element={ <Kive/> } />
            <Route exact path='/productos-kive-racionadores/' element={ <KiveRacionadores/> } />
            <Route exact path='/racionadores-empaque-kive/' element={ <RacionadoresEmpaqueKive/> } />
            <Route exact path='/racionadores-escritorio-kive/' element={ <RacionadoresEscritorioKive/> } />
            <Route exact path='/productos-rami/' element={ <Rami/> } />
            <Route exact path='/productos-rami-cintas/' element={ <RamiCintas/> } />
            <Route exact path='/productos-rami-hilos/' element={ <RamiHilos/> } />
            <Route exact path='/productos-mit/' element={ <ProductosMit/> } />
            <Route exact path='/productos-norte/' element={ <ProductosNorte/> } />
            
            {/* KIVE */}
            <Route exact path='/cinta-adhesiva-transparente/' element={ <CintaAdhesivaTransparente/> } />
            <Route exact path='/cinta-adhesiva-invisible/' element={ <CintaAdhesivaInvisible/> } />
            <Route exact path='/cinta-adhesiva-doble-faz-espumada/' element={ <CintaAdhesivaDobleFazEspumada/> } />
            <Route exact path='/cinta-de-papel-kive/' element={ <CintaPapelKive/> } />
            <Route exact path='/cinta-adhesiva-doble-faz-kive/' element={ <CintaAdhesivaDobleFaz/> } />
            <Route exact path='/cinta-empaque-kive/' element={ <CintaEmpaqueKive/> } />
            <Route exact path='/cinta-duct-tape-kive/' element={ <CintaDuctTape/> } />

            {/* RAMIE */}
            <Route exact path='/cinta-empaque-rami/' element={ <CintaEmpaqueRami/> } />
            <Route exact path='/cinta-papel-rami/' element={ <CintaPapelRami/> } />
            <Route exact path='/cinta-seguridad-rami/' element={ <CintaSeguridadRami/> } />
            <Route exact path='/hilos-plastico-rami/' element={ <HilosPlasticosRami/> } />
            <Route exact path='/hilos-sisal-rami/' element={ <HilosSisalRami/> } />
            <Route exact path='/hilos-algodon-crudo-rami/' element={ <HilosAlgodonCrudoRami/> } />
            <Route exact path='/hilos-algodon-pulido-rami/' element={ <HilosAlgodonPulidoRami/> } />


            <Route exact path='/contacto/' element={ <Contacto/> } />
            <Route path="*" element={<h2 className='text-center' style={{ paddingTop: 180, paddingBottom: 180}} >404 - Página no encontrada.</h2>}/>
          </Routes>
        <Footer /> 
      </HashRouter>

    </div>
  );
}

export default App;
