import { Container } from 'react-bootstrap'

import NavbarPages from './Commonds/NavbarPages'
import HeaderPages from './Commonds/HeaderPages'
import CardProductoInt from './Commonds/CardProductoInt'

const KiveRacionadores = () => {
  return (
    <Container  className='mb-0 ' >

        <NavbarPages className="p-0" />
        <div className='row'>
          <div className='col-12 mb-4 headerPagesConainer'>
            <HeaderPages img={"./images/header-productos-kive-cintas.jpg"} alt={"Productos Rami Cintas & Hilos"} />
          </div>
        </div>
        <div className='row productos__thumbs justify-content-md-center'>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'><CardProductoInt imgLink={'/racionadores-empaque-kive/'} marcoSvg={'007308'} colorProd={'#007308'} fontSizeProd={'1.7rem'} fontSize={'1.4rem'}  prod={"RACIONADORES"} tipoProd={'EMPAQUE'} /></div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'><CardProductoInt imgLink={'/racionadores-escritorio-kive/'} marcoSvg={'ff7d00'} colorProd={'#ff7d00'} fontSizeProd={'1.7rem'} fontSize={'1.4rem'}  prod={"RACIONADORES"} tipoProd={'ESCRITORIO'}  /></div>
         </div> 
      </Container>
    
  )
}

export default KiveRacionadores