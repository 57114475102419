import { Container } from 'react-bootstrap'

import NavbarPages from '../NavbarPages'
import HeaderPages from '../HeaderPages'
import KiveUso from '../../../Resourses/products/kive-uso-multiproposito.svg'

const imgHeader = "./images/productos/header-productos-kive-duc.jpg";
const imgThumbIzq = "./images/productos/productos_thumbs--duc-tape--kive.jpg";
const altText = "CINTA DEDUCT TAPE";

const tipoColor = 'productos__detail--tipoNegro'

const detailLine1 = "CINTA";
// const detailLine2 = "ADHESIVA";
const detailLine3 = "DUCT TAPE";

const imgTable ="./images/productos/kive/cuadro-kive-cinta-duct-tape.jpg";


const textoDetail = 'Cinta de tela multipropósito de alta resistencia, ideal para arreglos rápidos, ya que es fácil de rasgar a mano y lo suficientemente útil para reparaciones en el hogar, camping, taller, automóvil, entre otros.  Amplia variedad de colores.'

const imgThumb1 = './images/productos/kive/kive-cinta-duc-tape-1.jpg';
const imgThumb1Alt = 'CINTA DEDUCT TAPE.';

const imgThumb2 = './images/productos/kive/kive-cinta-duc-tape-3.jpg';
const imgThumb2Alt = 'CINTA DEDUCT TAPE.';

const imgThumb3 = './images/productos/kive/kive-cinta-duc-tape-2.jpg';
const imgThumb3Alt = 'CINTA DEDUCT TAPE.';



const CintaDuctTape = () => {
  return (
    <Container className='mb-0'>
        <NavbarPages className="p-0" />
        <div className='row'>
          <div className='col-12 mb-4'>
            <HeaderPages img={imgHeader} alt={"Productos Kive Cintas"} />
          </div>
          <div className='col-12 col-sm-6 col-md-5 productos__imgContainer'>
            <img 
              src={imgThumbIzq} 
              alt={altText} 
              className='d-block w-100 productos__imgContainer--img' 
              loading='lazy'/>
          </div>
          <div className='col-12 col-sm-12 col-md-7 productos__vert--int mb-4 mt-1'>
            <div className='row productos__detail'>
              <div className='col-12'>
                <p className={`productos__detail--tipo ${tipoColor}`}>{detailLine1}
                 {/* <span className={`productos__detail--caracteristica ${tipoColor}`}> {detailLine2}</span> */}
                </p>
                <p className={`productos__detail--caracteristica ${tipoColor}`}>{detailLine3}</p>
                <img src={KiveUso} className='d-block productos__detail--svg' alt='' loading="lazy" />
              </div>
              <div className='col-12 productos__detail'>
                <img  src={imgTable} 
                      className='d-block w-100' 
                      alt={altText}
                      loading="lazy" />
              </div>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-12 productos__vert mb-4 mt-1'>
            <p className='site_text productos__text--int mb-0'> 
              {textoDetail}
            </p>
          </div>
        </div>
        <div className='row'>
        
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb1} className='d-block w-100 p-0 m-0 ' alt={ imgThumb1Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb2} className='d-block w-100 p-0 m-0 ' alt={ imgThumb2Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb3} className='d-block w-100 p-0 m-0 ' alt={ imgThumb3Alt} loading="lazy" />
            </div>
        </div>       
      </Container>
    
  )
}

export default CintaDuctTape