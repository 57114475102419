import React from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'

import { Link } from 'react-router-dom'

import NavBarIcons from './NavBarIcons'
const NavbarSite = () => {
  return (  
          <Navbar  expand="lg pt-0">
            <Container className='navbar__container'>
              
              <Navbar.Brand className='pt-0' style={{ width: "50%"}}>
                <Link to={'/'} style={{ cursor: 'pointer'}}>
                  <img src='./images/navbar/navbar-logo.jpg' alt="Rami Cintas + Hilos" className='navbar-img' />
                </Link>
              </Navbar.Brand>
              
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              
              <Navbar.Collapse id="basic-navbar-nav" style={{ width: "50%"}}>
                <Nav className="me-auto row">
                  <div className='item__desktop p-0' >
                    <NavBarIcons />
                  </div>
                  <div>
                    <hr className='item__mobile'/>
                    <div className='row navbarlinks__container'>
                        <div className="col-6 col-lg-3 navbarlinks__btn navbarlinks__btn--left ">
                          <Link to={'/quienes-somos'} className='link__reset'> Quienes Somos </Link>
                        </div>
                        <div className="col-6 col-sm-2 navbarlinks__btn navbarlinks__btn--center">
                          <Link to={'/productos-kive'} className='link__reset'>KIVE </Link>
                        </div>
                        <div className="col-6 col-sm-2 navbarlinks__btn navbarlinks__btn--center">
                          <Link to={'/productos-rami'} className='link__reset'>RAMI </Link>
                        </div>
                        <div className="col-6 col-sm-3 navbarlinks__btn navbarlinks__btn--right ">
                          <Link to={'/contacto'} className='link__reset'>Contacto </Link>
                        </div>
                    </div>
                    <hr className='item__mobile'/>
                    <div className='row navbarlinks__list'>
                      <a href="https://www.facebook.com/CasaRamiSRL/" target={'_blank'} rel="noreferrer" style={{ cursor: 'pointer'}} className="item__mobile">Facebook</a>
                      <a href="https://www.instagram.com/rami_cintasadhesivas/" target={'_blank'} rel="noreferrer" style={{ cursor: 'pointer'}} className="item__mobile">Instagram</a>
                      <a href="https://www.linkedin.com/company/69259856/admin/dashboard/" target={'_blank'} rel="noreferrer" style={{ cursor: 'pointer'}} className="item__mobile">Linkedin</a>
                      <a href="https://wa.me/5491126273894" target={'_blank'} rel="noreferrer" style={{ cursor: 'pointer'}} className="item__mobile">WhastApp</a>
                      <a href="mailto:info@casarami.com.ar" target={'_blank'} rel="noreferrer" style={{ cursor: 'pointer'}} className="item__mobile">Email</a>
                      <a href="#link" target={'_blank'} rel="noreferrer" className="item__mobile"   
                        style={{ cursor: 'not-allowed', pointerEvents: 'none', opacity: 0.5 }}
                      >News</a>
                    </div>
                  </div>
                </Nav>
              </Navbar.Collapse>

            </Container>
          </Navbar>
      )
    }
    
  export default NavbarSite
