import React from 'react'
import { Container } from 'react-bootstrap'

const NavBarIcons = () => {
  return (
    <Container>
        <div className='row'>
            <img src="./images/navbar/navbar-linea-color.jpg" alt="Miscelanea" />
            
            <div className='col-2  navbar__icon'> 
                <a href="https://www.facebook.com/CasaRamiSRL" target={'_blank'} rel="noreferrer"  className='navbar__icon--facebook' style={{ cursor: 'pointer'}}>
                <img src='./images/navbar/nav-icon-facebook.png' alt='Facebook' className='navbar__iconred navbar-icon d-block w-100 ' />Facebook</a>
            </div>

            <div className='col-2  navbar__icon'> 
                <a href="https://www.instagram.com/rami_cintasadhesivas/" target={'_blank'} rel="noreferrer" className='navbar__icon--instagram' style={{ cursor: 'pointer'}}>
                <img src='./images/navbar/nav-icon-instagram.png' alt='Instagram' className='navbar__iconred navbar-icon d-block w-100 ' />Instagram</a>
            </div>

            <div className='col-2  navbar__icon'> 
                <a href="https://www.linkedin.com/company/69259856/admin/dashboard/" target={'_blank'} rel="noreferrer" className='navbar__icon--linkedin'  style={{ cursor: 'pointer'}}>
                <img src='./images/navbar/nav-icon-linkedin.png' alt='Linkedin' className='navbar__iconred navbar-icon d-block w-100 ' />Linkedin</a>
            </div>

            <div className='col-2  navbar__icon'> 
                <a href="https://wa.me/5491126273894" target={'_blank'} rel="noreferrer" className='navbar__icon--whatsapp' style={{ cursor: 'pointer'}}>
                <img src='./images/navbar/nav-icon-whatsapp.png' alt='WhastApp' className='navbar__iconred navbar-icon d-block w-100 ' />WhastApp</a>
            </div>

            <div className='col-2  navbar__icon '> 
                <a href="mailto:info@casarami.com.ar" target={'_blank'} rel="noreferrer" className='navbar__icon--email'  style={{ cursor: 'pointer'}}>
                <img src='./images/navbar/nav-icon-email.png' alt='Email' className='navbar__iconred navbar-icon d-block w-100 ' />Email</a>
            </div>

            <div className='col-2  navbar__icon' style={{ cursor: 'not-allowed', pointerEvents: 'none', opacity: 0.5 }}> 
                <a href="https://www.instagram.com/rami_cintasadhesivas/" target={'_blank'} rel="noreferrer"  className=' navbar__icon--news' style={{ cursor: 'pointer'}}>
                <img src='./images/navbar/nav-icon-news.png' alt='News' className='navbar__iconred navbar-icon d-block w-100 ' />News</a>
            </div>

        </div>
    </Container>
  )
}

export default NavBarIcons