import { Container } from 'react-bootstrap'

import NavbarPages from './Commonds/NavbarPages'
import HeaderPages from './Commonds/HeaderPages'
import CardProductoInt from './Commonds/CardProductoInt'

const Kive = () => {
  return (
    <Container  className='mb-0 ' >

        <NavbarPages className="p-0" />
        <div className='row'>
          <div className='col-12 mb-4 headerPagesConainer'>
            <HeaderPages img={"./images/header-productos-kive-cintas.jpg"} alt={"Productos Kive Cintas"} />
          </div>
        </div>
        <div className='row productos__thumbs justify-content-md-center'>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'><CardProductoInt imgLink={'/cinta-adhesiva-transparente/'} marcoSvg={'e70057'} colorProd={'#e70057'} fontSizeProd={'1.3rem'} fontSize={'1.6rem'}  prod={"CINTAS"} tipoProd={'ADHESIVAS TRANSPARENTES'} /></div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'><CardProductoInt imgLink={'/cinta-adhesiva-invisible/'} marcoSvg={'a400e7'} colorProd={'#a400e7'} fontSizeProd={'1.7rem'} fontSize={'2rem'}  prod={"CINTAS"} tipoProd={'INVISIBLE'}  /></div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'><CardProductoInt imgLink={'/cinta-adhesiva-doble-faz-espumada/'} marcoSvg={'009cd7'} colorProd={'#009cd7'} fontSizeProd={'1.7rem'} fontSize={'2rem'}  prod={"CINTAS"} tipoProd={'ADHESIVAS DOBLE FAZ ESPUMADAS'}  /></div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'><CardProductoInt imgLink={'/cinta-de-papel-kive/'} marcoSvg={'00188b'} colorProd={'#00188b'} fontSizeProd={'1.7rem'} fontSize={'2rem'}  prod={"CINTAS"} tipoProd={'DE PAPEL'}  /></div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'><CardProductoInt imgLink={'/cinta-adhesiva-doble-faz-kive/'} marcoSvg={'ff009d'} colorProd={'#ff009d'} fontSizeProd={'1.7rem'} fontSize={'1.8rem'}  prod={"CINTAS"} tipoProd={'DOBLE FAZ'}  /></div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'><CardProductoInt imgLink={'/cinta-empaque-kive/'} marcoSvg={'0072ff'} colorProd={'#0072ff'} fontSizeProd={'1.8rem'} fontSize={'1.4rem'}  prod={"CINTAS"} tipoProd={'DE EMPAQUE'}  /></div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'><CardProductoInt imgLink={'/cinta-duct-tape-kive/'} marcoSvg={'231f20'} colorProd={'#231f20'} fontSizeProd={'1.7rem'} fontSize={'1.8rem'}  prod={"CINTAS"} tipoProd={'DUCT TAPE'}/></div>
        </div> 
      </Container>
    
  )
}

export default Kive