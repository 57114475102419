import { Container } from 'react-bootstrap'
import Rami from '../Resourses/logo-rami.svg'
import Kive from '../Resourses/logo-kive.svg'

import NavbarPages from './Commonds/NavbarPages'
import HeaderPages from './Commonds/HeaderPages'

const QuienesSomos = () => {
  return (
    <Container  className='mb-0' >
        <NavbarPages className="p-0" />
        <div className='row'>
          <div className='col-12'>
            <HeaderPages img={"./images/header-quienes-somos.jpg"} alt={"Quienes somos - Rami"} />
          </div>
          <div className='col-12'>
            <p className='qSomosContainer qSomosContainer__text mb-0'> 
            Somos Casa Rami S.R.L., una empresa dedicada a la comercialización de Cintas Adhesivas e Hilos a lo largo y ancho del territorio argentino. Desde nuestros comienzos por el año 2009 al día de hoy, hemos recorrido un largo camino, sumando constantemente productos nuevos y soluciones para facilitar el trabajo e incrementar las ventas de nuestros clientes, apoyando e incentivando la empatía humana y comercial con el fin de construir nuevas y prósperas relaciones.
            <br />
            <br />
            Para atender las necesidades de cada cliente particular, contamos en todo el país con representantes altamente capacitados y experimentados para brindarles su asesoramiento y <strong>respuestas a cada consulta.</strong>
            <br />
            <br />
            Nuestras marcas KIVE® y RAMI®, han crecido durante los años y es con mucho orgullo que hoy podemos afirmar, gracias a la calidad de nuestros productos y nuestra vocación de servicio, se han posicionado como referentes del mercado nacional en los rubros de librería, papelería y gráfica.
            <br />
            <br />
            Es por eso <strong>que lo invitamos</strong> a recorrer las diferentes áreas de nuestro <strong>sitio web</strong>, para que conozca nuestra amplia línea de productos, donde seguramente va a encontrar la solución que está buscando para su negocio y actividades.
            </p>
            <div className='qSomosContainer qSomosContainer__logos '>
              <img src={ Kive } className="qSomosContainer__logos--item" alt="Kive" />
              <img src={ Rami } className="qSomosContainer__logos--item"  alt="Rami Cintas e Hilos" />
            </div>
          </div>
         
          
        </div>
      </Container>
    
  )
}

export default QuienesSomos