import React from 'react'
import { Carousel } from 'react-bootstrap' 


const HomeCarousel = () => {
  return (

    <Carousel className='homeCardProducto__container'>
      
      <Carousel.Item>
        <img
          className="d-block carousel__container--images"
          src="./images/carousel/arma-el-bricolage.jpg"
          alt="Ama el Bricolage"
        />
        <Carousel.Caption>
          <h3 className='carousel__titulo'>#Amá el<br/>bricolage</h3>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block carousel__container--images"
          src="./images/carousel/chico-con-caja-y-gorra.jpg"
          alt="Ama tus empaques con Rami"
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block carousel__container--images"
          src="./images/carousel/arma-tus-empaques.jpg"
          alt="Ama tus empaques con Rami"
        />
        <Carousel.Caption>
          <h3 className='carousel__titulo'>#Amá tus<br/>empaques</h3>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block carousel__container--images "
          src="./images/carousel/envolviendo-caja.jpg"
          alt="Ama tus entregas con Rami"
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block carousel__container--images"
          src="./images/carousel/arma-tus-entregas.jpg"
          alt="Ama tus empaques con Rami"
        />
        <Carousel.Caption>
          <h3 className='carousel__titulo'>#Amá tus<br/>entregas</h3>
        </Carousel.Caption>
      </Carousel.Item>
      
      <Carousel.Item>
        <img
          className="d-block carousel__container--images"
          src="./images/carousel/precaucion.jpg"
          alt="Precaucion"
        />
      </Carousel.Item>

    </Carousel>

  )
}

export default HomeCarousel