import { Container } from 'react-bootstrap'

import NavbarPages from './Commonds/NavbarPages'
import HeaderPages from './Commonds/HeaderPages'
import CardProductoInt from './Commonds/CardProductoInt'

const RamiCintas = () => {
  return (
    <Container  className='mb-0 ' >

        <NavbarPages className="p-0" />
        <div className='row'>
          <div className='col-12 mb-4 headerPagesConainer'>
            <HeaderPages img={"./images/header-productos-rami-cintas.jpg"} alt={"Productos Rami Cintas & Hilos"} />
          </div>
        </div>
        <div className='row productos__thumbs'>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'><CardProductoInt imgLink={'/cinta-papel-rami/'} marcoSvg={'007308'} colorProd={'#007308'} fontSizeProd={'1.7rem'} fontSize={'2rem'}  prod={"CINTAS"} tipoProd={'DE PAPEL'}  /></div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'><CardProductoInt imgLink={'/cinta-empaque-rami/'} marcoSvg={'6abd45'} colorProd={'#6abd45'} fontSizeProd={'1.4rem'} fontSize={'1.7rem'}  prod={"CINTAS"} tipoProd={'DE EMPAQUE'} /></div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'><CardProductoInt imgLink={'/cinta-seguridad-rami/'} marcoSvg={'febe10'} colorProd={'#febe10'} fontSizeProd={'1.7rem'} fontSize={'2rem'}  prod={"CINTAS DE"} tipoProd={'SEGURIDAD'}  /></div>
         </div> 
      </Container>
    
  )
}

export default RamiCintas