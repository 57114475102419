import React from 'react'
import { Container } from 'react-bootstrap'

const BannerHome = () => {
  return (
    <Container className="container  ">
        <div className='row justify-content-center HomeBannerContainer'>
          <div className='col-12 col-md-3'>
            <p className='HomeBannerContainer__number HomeBannerContainer__number--1 HomeBannerContainer__bloque1'>+15</p>
            <p className='HomeBannerContainer__text'>Años en el<br />mercado</p>
          </div>
          <div className='col-12 col-md-3'>
            <p className='HomeBannerContainer__number HomeBannerContainer__number--2 HomeBannerContainer__bloque1'>+100</p>
            <p className='HomeBannerContainer__text'>Productos<br/>en cartera</p>
          </div>
          <div className='col-12 col-md-3 text-center'>
            <p className='HomeBannerContainer__number HomeBannerContainer__number--3 HomeBannerContainer__bloque3'>
              <img src="/images/mapa-argentina.svg" alt="Argentina" class="HomeBannerContainer__mapa" />
            </p>
            <p className='HomeBannerContainer__text'>Presentes en<br/>todo el país</p>
          </div>
        </div>
    </Container>
  )
}

export default BannerHome