import React from 'react'
import { Link } from 'react-router-dom'

const CardProductoInt = ( { imgLink, marcoSvg, prod, tipoProd, colorProd, fontSize, fontSizeProd } ) => {
  return (
    <div className='productos__thumb'>
      <Link to={imgLink}>
        <div className={`productos__thumb--img productos__thumb--cont ${'productos__thumbs--'+marcoSvg}`} style={{ color: colorProd}}>
          <p className='productos__thumb--prod' style={{ fontSize: fontSize }}>{prod}</p>
          <p className='productos__thumb--tipoProd' style={{ fontSize: fontSizeProd }}>{tipoProd}</p>
        </div>
      </Link>
    </div>
  )
}

export default CardProductoInt