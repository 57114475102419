import { Container } from 'react-bootstrap'
import Rami from '../Resourses/logo-rami.svg'
import Kive from '../Resourses/logo-kive.svg'
import NavbarPages from './Commonds/NavbarPages'
import ButtonComponent from './Commonds/ButtonComponent'

const Contacto = () => {
  return (
    <Container  className='mb-0 ' >
        <NavbarPages className="p-0" />
        <div className='row'>
          <div className='col-12 contactoContainer'>
            <h2 className='contacto__titulo'>FORMULARIO DE CONTACTO</h2>
            <p className=' contacto__text mb-0'>                         
            Si necesitás contactarte con nosotros, completá el siguiente formulario
            y te respondemos a la brevedad
            </p>
            
            <p className='contacto__titulo--form'>Nombre</p>
            <input type="text"  className='contacto__input'/>

            <p className='contacto__titulo--form'>E-mail</p>
            <input type="text"  className='contacto__input'/>

            <p className='contacto__titulo--form'>Teléfono</p>
            <input type="text"  className='contacto__input'/>

            <p className='contacto__titulo--form'>Consulta</p>
            <input type="text"  className='contacto__input'/>

            <ButtonComponent text={"Enviar"} />
            <div className='qSomosContainer qSomosContainer__logos '>
              <img src={ Kive } className="qSomosContainer__logos--item" alt="Kive" />
              <img src={ Rami } className="qSomosContainer__logos--item"  alt="Rami Cintas e Hilos" />
            </div>
          </div>
         
          
        </div>
      </Container>
  )
}

export default Contacto