import { Container } from 'react-bootstrap'

import NavbarPages from './Commonds/NavbarPages'
import HeaderPages from './Commonds/HeaderPages'
import CardProductoInt from './Commonds/CardProductoInt'

const RamiHilos = () => {
  return (
    <Container  className='mb-0 ' >

        <NavbarPages className="p-0" />
        <div className='row'>
          <div className='col-12 mb-4 headerPagesConainer'>
            <HeaderPages img={"./images/header-productos-rami-cintas.jpg"} alt={"Productos Rami Cintas & Hilos"} />
          </div>
        </div>
        <div className='row productos__thumbs justify-content-md-center'>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'><CardProductoInt imgLink={'/hilos-plastico-rami/'} marcoSvg={'74c0eb'} colorProd={'#74c0eb'} fontSizeProd={'1.7rem'} fontSize={'2rem'}  prod={"HILOS"} tipoProd={'PLASTICOS'}  /></div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'><CardProductoInt imgLink={'/hilos-sisal-rami/'} marcoSvg={'d08100'} colorProd={'#d08100'} fontSizeProd={'1.7rem'} fontSize={'1.8rem'}  prod={"HILOS"} tipoProd={'SISAL/YUTE'}  /></div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'><CardProductoInt imgLink={'/hilos-algodon-crudo-rami/'} marcoSvg={'a9abae'} colorProd={'#a9abae'} fontSizeProd={'1.8rem'} fontSize={'1.4rem'}  prod={"HILOS"} tipoProd={'DE ALGODON CRUDO'}  /></div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-3'><CardProductoInt imgLink={'/hilos-algodon-pulido-rami/'} marcoSvg={'e6af23'} colorProd={'#e6af23'} fontSizeProd={'1.7rem'} fontSize={'1.8rem'}  prod={"HILOS"} tipoProd={'DE ALGODON PULIDO'}/></div>
        </div> 
      </Container>
    
  )
}

export default RamiHilos